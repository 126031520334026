import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import GameList from "../components/GameList";
import Entry from "../types/Entry";

import worlds from "../data/worlds.json";

const Main = () => {
    const [data, setData] = useState<Entry[]>([]);

    useEffect(() => {
        if (data.length !== 0)
            return;
        let items: Entry[] = []
        items = JSON.parse(window.sessionStorage.getItem("Items") || "[]") as Entry[]
        if (items.length !== 0)
            setData(items);
        else
            (async () => {
                items = worlds as Entry[];
                window.sessionStorage.setItem("Items", JSON.stringify(items));
                setData(items);
            })();
    }, [data]);

    return <Box 
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight='98vh'
    >
        { data.length === 0 ? <></> : <GameList data={data}/> }
    </Box>;
}
export default Main;