import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fab, Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const Obsidian = () => {
    const location = useLocation();
    const { src } = location.state
    
    const navigate = useNavigate();

    return <>
        <iframe src={src} title="web-view" frameBorder="0" style={{
            position: "absolute",
            height: "100vh",
            width: "100vw"
        }}/>
        <Tooltip 
            title="Return to Game List"
            placement="left"
            arrow
        >
            <Fab 
                color="secondary" 
                aria-label="home"
                onClick={() => navigate("/")}
                style={{
                    position: "absolute",
                    bottom: "2vh",
                    right: "1vw"
                }}
            >
                <HomeIcon />
            </Fab>
        </Tooltip>
    </>;
}
export default Obsidian;