import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from "@fortawesome/free-solid-svg-icons/faDiceD20";
import { faAtlas } from "@fortawesome/free-solid-svg-icons/faAtlas";

import moment from 'moment-timezone';

import Entry from "../types/Entry";

function isValidUrl(url: string | undefined) {
    console.log(url);
    if (url === undefined)
        return false;
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

type Props = {
    data: Entry[]
}

const GameList = (props: Props) => {
    const navigate = useNavigate();
    const data = props.data;

    const inputTime = moment(3, 'HH:mm')

    return (<TableContainer 
        component={Paper} 
        sx={{
            width:1/4, 
            minWidth:1/4,
            boxShadow:6
        }}
    > 
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell><Typography variant="h5">Name</Typography></TableCell>
                    <TableCell><Typography variant="h5">Date</Typography></TableCell>
                    <TableCell><Typography variant="h5">Links</Typography></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(e => {
                    //if (e.date !== undefined && e.date!.hour !== undefined)
                    //   date.setUTCHours(e.date!.hour!);
                    return <TableRow
                        key={e.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell><Typography variant="body1">{e.name}</Typography></TableCell>
                        <TableCell><Typography variant="body1">
                            {e.date === undefined ? "—" : e.date!.day 
                                + (e.date!.hour === undefined ? "" : ` at ${inputTime.tz("America/New_York").local().format('HH:mm')}`)
                            }
                        </Typography></TableCell>
                        <TableCell align="right">
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                <Link href={`https://${e.foundry}.the-breach.org/join`} color="inherit" sx={{pt: 0.2}}><FontAwesomeIcon icon={faDiceD20} size="lg"/></Link>
                                { e.lore === undefined ? "" : ( !isValidUrl(e.lore) ? <Link 
                                    onClick={() => navigate('/obsidian', {state: { src: `./worlds/${e.lore}/welcome.html` }})}
                                    component="button"
                                    color="inherit" sx={{pt: 0.2}}
                                ><FontAwesomeIcon icon={faAtlas} size="lg"/></Link>
                                : <Link 
                                    href={`${e.lore}`}
                                    color="inherit" sx={{pt: 0.2}}
                                ><FontAwesomeIcon icon={faAtlas} size="lg"/></Link>
                                )}
                            </Stack>
                        </TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    </TableContainer>);
}

export default GameList;