import React, { useCallback, useEffect, useState } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import type { Engine } from 'tsparticles-engine';

interface BackgroundProps {
    children?: React.ReactNode;
    darkMode?: boolean;
}

const Background = (props: BackgroundProps) => {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const [particles, setParticles] = useState({
        backgroundColor: '#4b14fc',
        particleColor: '#7802ee',
        linksColor: '#9042f2'
    })

    useEffect(() => {
        setParticles(particles); // Setup dark mode light mode later.
    }, [props.darkMode, particles]);

    return (
        <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
            background: {
                color: {
                    value: particles.backgroundColor,
                },
            },
            fpsLimit: 60,
            fullScreen: {
                enable: true,
                zIndex: -1,
            },
            interactivity: {
                detectsOn: 'window',
                events: {
                    onClick: {
                        enable: false,
                        mode: 'push',
                    },
                    onHover: {
                        enable: true,
                        mode: 'repulse',
                    },
                    resize: true,
                },
                modes: {
                    bubble: {
                        distance: 40,
                        duration: 2,
                        opacity: 0.4,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 150,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: particles.particleColor,
                },
                links: {
                    color: particles.linksColor,
                    distance: 150,
                    enable: true,
                    opacity: 0.7,
                    width: 2,
                    triangles: {
                        enable: true,
                        color: particles.linksColor,
                        opacity: 0.2,
                    },
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: true,
                    speed: 4,
                    straight: false,
                    attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                    },
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 2000,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.9,
                },
                shape: {
                    type: 'polygon',
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
            detectRetina: true,
        }}
        />
    );
};

export default Background;