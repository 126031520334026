import React from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Background from "./components/Background";

let darkTheme = responsiveFontSizes(createTheme({
	typography: {
	  	fontSize: 12
	},
	palette: {
		mode: 'dark'
	}
}));

type Props = {
	content: React.ReactNode;
};

function App(props: Props) {
	darkTheme = responsiveFontSizes(darkTheme);
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			{props.content}
			<img src="/cat.gif" alt="loading..." style={{position:"absolute", left:0, bottom:0}} />
			<img src="/cat.gif" alt="loading..." style={{position:"absolute", right:0, bottom:0}} />
			<Background />
		</ThemeProvider>
	);
}

export default App;
